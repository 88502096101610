import { RefObject, useRef } from "react";
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, ButtonProps } from "src/components/base/button";

export interface IWfAlertDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmButtonText: string;
    confirmButtonProps?: Omit<ButtonProps, "onClick">;
    header: string;
    body: string;
    wikifolioShortDescription?: string;
    finalFocusRef?: RefObject<HTMLButtonElement>;
}

export const WfAlertDialog = ({
    onClose,
    onConfirm,
    isOpen,
    confirmButtonText,
    confirmButtonProps,
    header,
    body,
    wikifolioShortDescription,
    finalFocusRef,
}: IWfAlertDialogProps) => {
    const { t } = useTranslation("common");
    const cancelRef = useRef<HTMLButtonElement>(null);

    if (!isOpen) {
        return null;
    }

    return (
        <AlertDialog isOpen leastDestructiveRef={cancelRef} finalFocusRef={finalFocusRef ?? undefined} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent maxW={["100%", "750px", "708px"]}>
                    <AlertDialogHeader>{header}</AlertDialogHeader>
                    <AlertDialogBody>{body}</AlertDialogBody>
                    <AlertDialogFooter>
                        <Stack direction={["column", "row-reverse"]} w={["100%", null]} spacing={2}>
                            <Button onClick={onConfirm} {...confirmButtonProps} data-gtm-wikifolio-short-description={wikifolioShortDescription}>
                                {confirmButtonText}
                            </Button>
                            <Button variant="outline" ref={cancelRef} onClick={onClose}>
                                {t("cancel")}
                            </Button>
                        </Stack>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
